@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BrokenConsole-Bold';
  src: url(./fonts/BrokenConsoleBold.otf);
}

@font-face {
  font-family: 'PIXEL CRAFT';
  src: url(./fonts/PIXELCRAFT.otf);
}

html {
  @apply h-full bg-dark text-white;
}

.nav-link.active {
  /* @apply bg-white/30; */
  @apply text-white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.title {
  font-family: 'PIXEL CRAFT';
  color: transparent;
  background: linear-gradient(180deg, #fff 65%, #fff 65% 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-filter: drop-shadow(5px 5px 0 #000);
  filter: drop-shadow(5px 5px 0 #000000);
  font-variant: no-common-ligatures;
}

.updating {
  animation: turn 1s infinite linear;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fixed-table thead > tr:first-child > th:first-child:before,
.fixed-table thead > tr:nth-child(2) > th:nth-child(3):before,
.fixed-table tbody > tr > td:nth-child(3):before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: #0000000f;
  transform: translateY(-50%);
  /* transition: background-color 0.1s; */
  content: '';
}
.fixed-table thead > tr:first-child > th:first-child:after,
.fixed-table thead > tr:nth-child(2) > th:nth-child(3):after,
.fixed-table tbody > tr > td:nth-child(3):after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translate(100%);
  /* transition: box-shadow 0.1s; */
  content: '';
  pointer-events: none;
}
.fixed-table.active thead > tr:first-child > th:first-child:after,
.fixed-table.active thead > tr:nth-child(2) > th:nth-child(3):after,
.fixed-table.active tbody > tr > td:nth-child(3):after {
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 45%);
  transition: box-shadow 0.05s ease;
}

/* .fixed-table thead > tr:first-child > th:first-child,
.fixed-table thead > tr:nth-child(2) > th:nth-child(-n + 3),
.fixed-table tbody > tr > td:nth-child(-n + 3) {
  position: sticky !important;
  left: 0;
  @apply bg-gray-900 z-50;
}
.fixed-table thead > tr:nth-child(2) > th:nth-child(2),
.fixed-table tbody > tr > td:nth-child(2) {
  left: 80px;
}
.fixed-table thead > tr:nth-child(2) > th:nth-child(3),
.fixed-table tbody > tr > td:nth-child(3) {
  left: 190px;
} */
